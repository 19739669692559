html:root {
  --lc-color-brand-1: #F7F8FF;
  --lc-color-brand-2: #ECEFFF;
  --lc-color-brand-3: #B3B6E1;
  --lc-color-brand-4: #9BAAF4;
  --lc-color-brand-5: #5158B8;
  --lc-color-brand-6: #2C34AA;
  --lc-color-brand-7: #0F1787;
  --lc-color-border-1: #F2F3F5;
  --lc-color-border-2: #E3E7ED;
  --lc-color-border-3: #D8DEE8;
  --lc-color-border-4: #D0D8E4;
  --lc-color-border-5: #C9CDD4;
  --lc-color-border-6: #727F9F;
  --lc-color-fill-1: #FAFBFF;
  --lc-color-fill-2: #FAFAFC;
  --lc-color-fill-3: #F7F8FA;
  --lc-color-fill-4: #F3F4F6;
  --lc-color-fill-5: #E5E6EB;
  --lc-color-fill-6: #C9CDD4;
  --lc-color-fill-7: #0F2347;
  --lc-color-text-8: #071022;
  --lc-color-text-7: #0F2347;
  --lc-color-text-6: #3E4B6C;
  --lc-color-text-5: #606D8A;
  --lc-color-text-4: #727F9F;
  --lc-color-text-3: #999FAA;
  --lc-color-text-2: #C9CDD4;
  --lc-color-text-1: #FFFFFF;
  --lc-color-success-6: #008A4A;
  --lc-color-success-5: #00AB5B;
  --lc-color-success-4: #00BF66;
  --lc-color-success-3: #8DE0B8;
  --lc-color-success-2: #D2F4E4;
  --lc-color-success-1: #E8FFF5;
  --lc-color-warning-1: #FFF3E2;
  --lc-color-warning-2: #FFE3BA;
  --lc-color-warning-3: #FFC369;
  --lc-color-warning-4: #FFAD33;
  --lc-color-warning-5: #F5970A;
  --lc-color-warning-6: #D68100;
  --lc-color-danger-6: #BF0D00;
  --lc-color-danger-5: #DA1103;
  --lc-color-danger-4: #FF3B30;
  --lc-color-danger-3: #FE8780;
  --lc-color-danger-2: #FFD6D4;
  --lc-color-danger-1: #FFF6F6;
  --lc-font-size-small: 12px;
  --lc-font-size-base: 14px;
  --lc-font-size-medium: 16px;
  --lc-font-size-large: 20px;
  --lc-font-size-extra-large: 24px;
  --lc-line-height-small: 20px;
  --lc-line-height-base: 22px;
  --lc-line-height-medium: 24px;
  --lc-line-height-large: 28px;
  --lc-line-height-extra-large: 32px;
  --lc-padding-medium: 8px;
  --lc-padding-large: 16px;
  --lc-color-icon-1: #B8BCC5;
}
html:root body.crs-body {
  --lc-color-brand-1: #FFFAE8;
  --lc-color-brand-2: #FFF3CB;
  --lc-color-brand-3: #FDCF90;
  --lc-color-brand-4: #FDCF90;
  --lc-color-brand-5: #FFD850;
  --lc-color-brand-6: #FFC600;
  --lc-color-brand-7: #FFB400;
  --lc-color-brand-8: #FFAD3D;
  --lc-color-brand-9: #FF9505;
  --lc-color-border-1: #EEEEEE;
  --lc-color-border-2: #E3E3E3;
  --lc-color-border-3: #DDDDDD;
  --lc-color-border-4: #CCCCCC;
  --lc-color-border-5: #AAAAAA;
  --lc-color-border-6: #777C85;
  --lc-color-fill-1: #FAFAFA;
  --lc-color-fill-2: #F8FBF8;
  --lc-color-fill-3: #F4F4F4;
  --lc-color-fill-4: #EEEEEE;
  --lc-color-fill-5: #E8E8E8;
  --lc-color-fill-6: #CCCCCC;
  --lc-color-fill-7: #071022;
  --lc-color-text-8: #071022;
  --lc-color-text-7: #071022;
  --lc-color-text-6: #5C5F66;
  --lc-color-text-5: #6B6F77;
  --lc-color-text-4: #777C85;
  --lc-color-text-3: #999FAA;
  --lc-color-text-2: #CCCCCC;
  --lc-color-text-1: #FFFFFF;
  --lc-color-success-6: #008A4A;
  --lc-color-success-5: #00AB5B;
  --lc-color-success-4: #00BF66;
  --lc-color-success-3: #8DE0B8;
  --lc-color-success-2: #D2F4E4;
  --lc-color-success-1: #E8FFF5;
  --lc-color-warning-1: #FFF3E2;
  --lc-color-warning-2: #FFE3BA;
  --lc-color-warning-3: #FFC369;
  --lc-color-warning-4: #FFAD33;
  --lc-color-warning-5: #F5970A;
  --lc-color-warning-6: #D68100;
  --lc-color-danger-6: #BF0D00;
  --lc-color-danger-5: #DA1103;
  --lc-color-danger-4: #FF3B30;
  --lc-color-danger-3: #FE8780;
  --lc-color-danger-2: #FFD6D4;
  --lc-color-danger-1: #FFF6F6;
}
.lc-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: var(--lc-line-height-extra-large);
  padding-left: var(--lc-padding-large);
  padding-right: var(--lc-padding-large);
  border-radius: 2px;
}
.lc-button.lc-button-icon {
  border: 0;
  border-radius: 2px;
  width: 24px;
}
.lc-button.el-button--text {
  border: 0;
  border-radius: 2px;
  color: var(--lc-color-text-6);
  height: var(--lc-line-height-medium);
  padding-left: var(--lc-padding-medium);
}
.lc-button.el-button--text:hover {
  color: var(--lc-color-text-6);
  background-color: var(--lc-color-fill-4);
}
.lc-button.el-button--primary {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-6);
  border-color: var(--lc-color-brand-6);
  height: var(--lc-line-height-extra-large);
}
.lc-button.el-button--primary:hover {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-5);
  border-color: var(--lc-color-brand-5);
}
.lc-button.el-button--primary:active {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-7);
  border-color: var(--lc-color-brand-7);
}
.lc-button.is-disabled,
.lc-button.is-disabled:hover,
.lc-button.is-disabled:active,
.lc-button.is-disabled:focus {
  color: var(--lc-color-text-2);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-text-2);
}
.lc-button.el-button--default {
  color: var(--lc-color-brand-6);
  background-color: var(--lc-color-text-1);
  border-color: var(--lc-color-brand-6);
  height: var(--lc-line-height-extra-large);
}
.lc-button.el-button--default:hover {
  color: var(--lc-color-brand-6);
  background-color: var(--lc-color-brand-1);
  border-color: var(--lc-color-brand-4);
}
.lc-button.el-button--default:active {
  color: var(--lc-color-brand-7);
  background-color: var(--lc-color-brand-2);
  border-color: var(--lc-color-brand-7);
}
.lc-button.is-disabled,
.lc-button.is-disabled:hover,
.lc-button.is-disabled:active,
.lc-button.is-disabled:focus {
  color: var(--lc-color-text-2);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-text-2);
}
.lc-button.el-button--info {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-text-1);
  border-color: var(--lc-color-fill-6);
  height: var(--lc-line-height-extra-large);
}
.lc-button.el-button--info:hover {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-fill-6);
}
.lc-button.el-button--info:active {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-5);
  border-color: var(--lc-color-fill-6);
}
.lc-button.is-disabled,
.lc-button.is-disabled:hover,
.lc-button.is-disabled:active,
.lc-button.is-disabled:focus {
  color: var(--lc-color-text-2);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-text-2);
}
.lc-button.el-button--close {
  font-size: var(--lc-font-size-medium);
  width: 16px;
  height: 16px;
  color: var(--lc-color-text-3);
  background-color: transparent;
  border: none;
  padding: 0;
}
.lc-button.el-button--close:hover {
  color: var(--lc-color-brand-6);
  background-color: transparent;
  border: none;
  padding: 0;
}
.lc-button.el-button--close:active {
  color: var(--lc-color-brand-6);
  background-color: transparent;
  border: none;
  padding: 0;
}
.lc-button + .lc-button {
  margin-left: 8px;
}
.el-table .lc-button {
  border: 0;
  border-radius: 2px;
}
.el-table .lc-button.el-button--text {
  color: var(--lc-color-brand-6);
  padding-left: var(--lc-padding-medium);
  padding-right: var(--lc-padding-medium);
}
.el-table .lc-button.el-button--text:hover {
  color: var(--lc-color-brand-6);
  background-color: var(--lc-color-brand-2);
}
.el-table .lc-button-group {
  display: inline-block;
}
.el-table .lc-button-group .lc-button.el-button--text {
  border: 0;
  border-radius: 2px;
  margin: 0;
}
.el-table .lc-button-group .lc-button-icon + .lc-button-icon {
  margin-left: 4px;
}
.el-table .lc-button-group .lc-button-icon-last {
  margin-right: 12px !important;
}
.el-table .lc-button-group .el-button--text + .el-button--text::before {
  content: '';
  width: 0px;
  background-color: none;
  height: 16px;
  display: block;
  position: absolute;
  left: 0px;
}
.el-table .lc-button-group .lc-button-icon-last + .el-button--text::before {
  content: '';
  width: 1px;
  background-color: var(--lc-color-border-2);
  height: 16px;
  display: block;
  position: absolute;
  left: -4.5px;
}
.lc-button-group {
  display: inline-block;
}
.lc-button-group .lc-button.el-button--text {
  border: 0;
  border-radius: 2px;
  margin: 0 4px;
}
.lc-button-group .el-button--text + .el-button--text::before {
  content: '';
  width: 1px;
  background-color: var(--lc-color-border-2);
  height: 16px;
  display: block;
  position: absolute;
  left: -4.5px;
}
.lc-button-group.lc-btn-group-switch {
  display: flex;
}
.lc-button-group.lc-btn-group-switch .lc-button {
  background-color: var(--lc-color-text-1);
}
.lc-button-group.lc-btn-group-switch .lc-button.el-button--default {
  flex: 1;
  min-width: 0;
  color: var(--lc-color-text-3);
  border-color: var(--lc-color-fill-6);
}
.lc-button-group.lc-btn-group-switch .lc-button.el-button--default:hover {
  color: var(--lc-color-border-6);
  border-color: var(--lc-color-border-6);
}
.lc-button-group.lc-btn-group-switch .lc-button.el-button--default:active {
  color: var(--lc-color-brand-6);
  border-color: var(--lc-color-brand-6);
}
.lc-button-group.lc-btn-group-switch .lc-button.el-button--default:focus {
  color: var(--lc-color-brand-6);
  border-color: var(--lc-color-brand-6);
}
[class*=" el-icon-"],
[class^=el-icon-] {
  font-size: var(--lc-font-size-medium);
}
.el-button [class*=el-icon-] + span {
  margin-left: 4px;
}
.el-popover.lcNewPopperClass {
  font-size: 12px;
  color: var(--lc-color-fill-7);
  line-height: 20px;
  font-weight: 400;
  background: #fff;
  padding: 8px 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: left;
  max-width: 750px;
}
.componentRules .el-popover__reference {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  width: fit-content;
}
.componentRules .el-popover__reference i.a-rezenonepc-Help,
.componentRules .el-popover__reference i.a-rezenonepc-Information {
  margin-right: 4px;
}
.componentRules .el-popover__reference .default {
  color: #0F2347;
  display: flex;
  align-items: center;
}
.componentRules .el-popover__reference .noun,
.componentRules .el-popover__reference .ability {
  color: #999FAA;
  display: flex;
  align-items: center;
}
.lc-message-box {
  position: relative;
  border-radius: 2px;
  padding-bottom: 16px;
}
.lc-message-box .el-message-box {
  position: relative;
  border-radius: 2px;
}
.lc-message-box .el-message-box__container {
  position: unset;
}
.lc-message-box .el-message-box__status {
  top: 44px;
  left: 24px;
}
.lc-message-box .el-icon-warning:before {
  font-size: 30px;
}
.lc-message-box .el-icon-close:before {
  font-size: 20px;
}
.lc-message-box .el-message-box__message {
  margin-left: 12px;
  font-size: 14px;
  color: var(--lc-color-text-5);
  font-weight: 400;
}
.lc-message-box .el-message-box__message p {
  line-height: 20px;
}
.lc-message-box .el-message-box__title {
  margin-left: 46px;
  margin-top: 18px;
  font-size: 18px;
  color: #0F2347;
  font-weight: 400;
}
.lc-message-box .el-message-box__content {
  padding: 0 15px 25px;
}
.lc-message-box .el-message-box__status.el-icon-warning {
  color: var(--lc-color-warning-4);
}
.lc-message-box .el-message-box__btns {
  display: flex;
  flex-direction: row-reverse;
}
.lc-message-box .el-message-box__btns .el-button--small {
  height: var(--lc-line-height-extra-large);
  width: 88px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: unset;
}
.lc-message-box .el-message-box__btns .el-button--default {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-text-1);
  border-color: var(--lc-color-fill-6);
  height: var(--lc-line-height-extra-large);
}
.lc-message-box .el-message-box__btns .el-button--default:hover {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-fill-6);
}
.lc-message-box .el-message-box__btns .el-button--default:active {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-5);
  border-color: var(--lc-color-fill-6);
}
.lc-message-box .el-message-box__btns .el-button--primary {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-6);
  border-color: var(--lc-color-brand-6);
  height: var(--lc-line-height-extra-large);
}
.lc-message-box .el-message-box__btns .el-button--primary:hover {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-5);
  border-color: var(--lc-color-brand-5);
}
.lc-message-box .el-message-box__btns .el-button--primary:active {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-7);
  border-color: var(--lc-color-brand-7);
}
.lc-message-box .el-message-box__btns button:nth-child(2) {
  margin-right: 8px;
}
.lc-tab-box .el-tabs__nav-scroll {
  padding: 0 20px;
  background-color: #fff;
}
.lc-tab-box .el-tabs__nav-wrap:after {
  content: '';
  display: block;
  height: 1px;
  margin: 0 20px;
  width: calc(100% - 40px);
  background-color: var(--lc-color-border-2);
  clear: both;
}
.lc-tab-box .el-tabs__active-bar {
  background-color: var(--lc-color-brand-6);
  height: 2px;
}
.lc-tab-box .el-tabs__item.is-active {
  font-size: 14px;
  color: var(--lc-color-text-7);
  font-weight: 700;
}
.lc-tab-box .el-tabs__item {
  height: 44px;
  padding: 2px 16px;
  color: var(--lc-color-text-6);
}
.lc-tab-box .el-tabs__item:hover {
  color: var(--lc-color-text-7);
}
.lc-step .el-step__head {
  position: unset;
  width: 0%;
}
.lc-step .el-step__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
}
.lc-step .el-step__title.is-process {
  font-weight: normal;
  color: var(--lc-color-text-7);
}
.lc-step .el-step__description {
  font-size: 12px;
  color: var(--lc-color-text-5);
}
.lc-step .el-step__description div {
  font-size: 12px;
}
.lc-step .el-step__icon {
  width: unset;
  border: unset;
}
.lc-step .is-process .waiting {
  color: #D8D8D8;
}
.lc-upload {
  width: 336px;
}
.lc-upload .el-upload__tip {
  margin-top: 8px;
  color: var(--lc-color-text-3);
}
.lc-upload .el-upload-list--text {
  margin-top: 12px !important;
}
.lc-upload .el-upload-list--text .el-upload-list__item {
  height: 32px;
  line-height: 32px;
}
.lc-upload .el-upload-list--text .el-upload-list__item .el-icon-close {
  top: 8px;
}
.lc-upload .el-upload-list--picture-card .el-upload-list__item {
  width: 88px;
  height: 88px;
  margin-right: 8px !important;
  border-radius: 2px;
}
.lc-upload .el-upload-list--picture-card .el-upload-list__item .el-upload-list__item-delete {
  font-size: 12px !important;
}
.lc-upload .el-upload-list--picture-card .el-upload-list__item-delete {
  margin-left: 2px;
}
.lc-upload .el-upload--picture-card {
  width: 88px;
  height: 88px;
  line-height: 88px;
  border-radius: 2px;
  border: 1px dashed var(--lc-color-border-3);
}
.lc-upload .el-upload--picture-card .el-icon-plus {
  font-size: 16px;
}
.lc-upload .el-upload--picture-card:hover {
  border: 1px dashed var(--lc-color-border-6);
}
.el-tag {
  height: 22px;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 2px;
}
.el-tag--plain {
  color: #00BF66;
  border: 1px solid #00BF66;
}
.el-tag--dark {
  color: #FFF;
  border: 1px solid #F5970A;
  background: #F5970A;
}
.lc-table .el-table .el-table__body tr.hover-row > td {
  background-color: var(--lc-color-fill-3);
}
.lc-table .el-table .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: var(--lc-color-fill-3);
}
.lc-table .el-table th {
  padding: 3px 0;
  background: var(--lc-color-fill-4);
  color: var(--lc-color-text-5);
  font-weight: 400;
}
.lc-table .el-table th .cell {
  color: var(--lc-color-text-5);
}
.lc-table .el-table th.is-sortable {
  padding: 0px !important;
}
.lc-table .el-table td {
  min-height: 40px;
  padding: 3px 0;
}
.lc-table .el-table .el-tooltip {
  max-width: none;
  white-space: pre-wrap;
  /* 保留空格，并在需要时进行换行 */
  word-break: break-all;
  /* 在单词内换行 */
}
.lc-table .el-table .cell {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 34px;
  color: var(--lc-color-text-7);
}
.lc-table .el-table .cell .el-button-group {
  margin-left: -4px;
}
.lc-table .el-table .is-center .cell {
  text-align: center;
}
.lc-table .el-table .ic_sort {
  padding: 4px;
  cursor: grab !important;
  color: var(--lc-color-text-3);
}
.lc-table .el-table .ic_sort:hover {
  color: var(--lc-color-text-7);
}
.lc-table .el-table .sortable-ghost .ic_sort {
  cursor: grabbing !important;
}
.lc-table .el-table .cell-always-highlight {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--lc-color-brand-6);
}
.lc-table .el-table .cell-always-highlight:hover {
  color: var(--lc-color-brand-5);
  cursor: pointer;
}
.lc-table .el-table.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: var(--lc-color-fill-3);
}
.lc-table .el-table--border td,
.lc-table .el-table--border th {
  border-right: 1px solid var(--lc-color-border-2);
}
.lc-pagination.el-pagination.is-background {
  padding: 0;
}
.lc-pagination.el-pagination.is-background button,
.lc-pagination.el-pagination.is-background ul li {
  width: 32px;
  height: 32px;
  border: 1px solid var(--lc-color-border-3);
  border-radius: 2px;
  box-sizing: border-box;
  margin-left: 8px !important;
  font-weight: 400;
  line-height: 32px;
  padding: unset;
  background: #ffffff;
}
.lc-pagination.el-pagination.is-background button i {
  color: var(--lc-color-text-3);
  font-size: 16px;
  font-weight: 400;
}
.lc-pagination.el-pagination.is-background .btn-prev {
  margin: 0;
}
.lc-pagination.el-pagination.is-background .btn-next {
  margin: 0;
}
.lc-pagination.el-pagination.is-background button:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-pagination.el-pagination.is-background li {
  color: var(--lc-color-text-7);
}
.lc-pagination.el-pagination.is-background li.active {
  border: 1px solid var(--lc-color-brand-6) !important;
  background-color: #ffffff !important;
  color: var(--lc-color-brand-6) !important;
}
.lc-pagination.el-pagination.is-background li:hover {
  color: var(--lc-color-text-7) !important;
  border: 1px solid var(--lc-color-border-5);
}
.lc-pagination.el-pagination.is-background .el-pagination__total {
  font-size: 14px;
  color: var(--lc-color-text-7);
  margin-right: 8px;
  line-height: 32px;
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes {
  margin-right: 16px;
  height: 32px;
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-select .el-input {
  width: 98px;
  margin: 0;
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-select .el-input .el-input__inner {
  font-size: 14px;
  color: var(--lc-color-text-7);
  height: 32px;
  padding-right: 26px;
  padding-left: 8rpx;
  border-radius: 2px;
  border: 1px solid var(--lc-color-border-3);
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-select .el-input .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-select .el-input .el-select__caret {
  font-size: 20px;
  color: var(--lc-color-text-3);
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-select .el-input .el-input__suffix {
  position: absolute;
  height: 100%;
  right: 4px;
  top: 0;
  text-align: center;
  color: var(--lc-color-text-3);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.lc-pagination.el-pagination.is-background .el-pagination__sizes .el-input.is-focus .el-input__inner {
  border: 1px solid var(--lc-color-brand-6) !important;
}
.lc-search-form-collapse {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  padding-left: 18px;
}
.lc-search-form-collapse .filter-margin {
  margin-right: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.lc-search-form-collapse .filter-margin .lc-date-picker.has-left.is-active {
  z-index: 1000;
  box-shadow: -1px 0px 0 0 #2c34aa;
}
.lc-search-form-collapse .filter-margin .lc-date-picker.has-left.is-active .el-range-input {
  color: var(--lc-color-text-3);
}
.lc-search-form-collapse .filter-margin .lc-date-picker.has-left {
  z-index: 1000;
  border-left: none;
}
.lc-search-form-collapse .filter-margin .lc-date-picker.has-left .el-range-input {
  font-family: MicrosoftYaHei;
  color: var(--lc-color-text-7);
}
.lc-search-form-collapse .filter-margin .lc-select {
  width: unset;
}
.lc-search-form-collapse .filter-margin .lc-select.prefix-text-select .el-input.el-input--prefix span.el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-select.prefix-icon-select .el-input.el-input--prefix span.el-input__prefix {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--lc-color-text-7);
}
.lc-search-form-collapse .filter-margin .lc-select.prefix-text-select .el-input.el-input--prefix .el-input__inner,
.lc-search-form-collapse .filter-margin .lc-select.prefix-icon-select .el-input.el-input--prefix .el-input__inner {
  width: 180px;
}
.lc-search-form-collapse .filter-margin .lc-select.prefix-icon-select .el-input.el-input--prefix span.el-input__prefix {
  color: var(--lc-color-text-3);
}
.lc-search-form-collapse .filter-margin .lc-select .el-tag--info {
  margin: 2px 0 2px 8px;
}
.lc-search-form-collapse .filter-margin .lc-select .el-tag--info:nth-child(2) {
  margin-left: 4px;
}
.lc-search-form-collapse .filter-margin .lc-select.remove-arrow .el-input__suffix {
  display: none;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText {
  height: 32px;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText .el-select__input {
  padding-left: 96px;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText .el-select__tags {
  max-width: 238px !important;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText.hasData .el-select__tags {
  padding-left: 104px;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText.hasData .el-select__tags .el-select__input {
  padding-left: 0;
  margin-left: 0;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText .el-input.el-input--prefix .el-input__inner {
  padding-left: 108px;
  font-size: 14px;
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText .el-input.el-input--prefix:not(.noLabel) span.el-input__prefix {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  padding-left: 4px;
  color: var(--lc-color-text-7);
}
.lc-search-form-collapse .filter-margin .lc-select.muiltSelect.prefixText.el-select .el-tag {
  max-width: 50%;
  border-radius: 2px;
  padding: 2px 6px 2px 8px;
  font-size: 12px;
}
.lc-search-form-collapse .filter-margin .lc-select.has-right .el-input input {
  width: 96px;
}
.lc-search-form-collapse .filter-margin .lc-select.has-right .el-input:focus,
.lc-search-form-collapse .filter-margin .lc-select.has-right .el-input:active {
  border-color: var(--lc-color-primary);
  z-index: 1000;
}
.lc-search-form-collapse .filter-margin .lc-select.has-right .el-input:focus-within {
  z-index: 2;
}
.lc-search-form-collapse .filter-margin .lc-input .el-input__inner,
.lc-search-form-collapse .filter-margin .prefix-text-select .el-input__inner,
.lc-search-form-collapse .filter-margin .prefix-icon-select .el-input__inner {
  width: unset;
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-input input {
  width: 240px;
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-input input::placeholder,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-input input::placeholder,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-input input::placeholder {
  color: var(--lc-color-text-3);
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-input .el-input__prefix {
  height: 32px;
  line-height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: 400;
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-input .el-input__prefix .el-input__icon,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-input .el-input__prefix .el-input__icon,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-input .el-input__prefix .el-input__icon {
  height: 32px;
  line-height: 32px;
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-icon-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-icon-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-icon-input input {
  padding-left: 30px;
}
.lc-search-form-collapse .filter-margin .lc-input.prefix-icon-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.prefix-icon-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.prefix-icon-input .el-input__prefix {
  color: var(--lc-color-text-3);
}
.lc-search-form-collapse .filter-margin .lc-input.six-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.six-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.six-word-prefix-input input {
  padding-left: 100px;
}
.lc-search-form-collapse .filter-margin .lc-input.five-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.five-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.five-word-prefix-input input {
  padding-left: 86px;
}
.lc-search-form-collapse .filter-margin .lc-input.four-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.four-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.four-word-prefix-input input {
  padding-left: 72px;
}
.lc-search-form-collapse .filter-margin .lc-input.three-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.three-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.three-word-prefix-input input {
  padding-left: 58px;
}
.lc-search-form-collapse .filter-margin .lc-input.two-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.two-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.two-word-prefix-input input {
  padding-left: 44px;
}
.lc-search-form-collapse .filter-margin .lc-input.one-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.one-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.one-word-prefix-input input {
  padding-left: 30px;
}
.lc-search-form-collapse .filter-margin .lc-input.one-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.one-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.one-word-prefix-input input,
.lc-search-form-collapse .filter-margin .lc-input.two-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.two-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.two-word-prefix-input input,
.lc-search-form-collapse .filter-margin .lc-input.three-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.three-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.three-word-prefix-input input,
.lc-search-form-collapse .filter-margin .lc-input.four-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.four-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.four-word-prefix-input input,
.lc-search-form-collapse .filter-margin .lc-input.five-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.five-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.five-word-prefix-input input,
.lc-search-form-collapse .filter-margin .lc-input.six-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-text-select.six-word-prefix-input input,
.lc-search-form-collapse .filter-margin .prefix-icon-select.six-word-prefix-input input {
  width: 180px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: var(--lc-color-text-7);
  font-weight: 400;
}
.lc-search-form-collapse .filter-margin .lc-input.one-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.one-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.one-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-input.two-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.two-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.two-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-input.three-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.three-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.three-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-input.four-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.four-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.four-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-input.five-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.five-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.five-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .lc-input.six-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-text-select.six-word-prefix-input .el-input__prefix,
.lc-search-form-collapse .filter-margin .prefix-icon-select.six-word-prefix-input .el-input__prefix {
  margin-left: 5px;
  color: var(--lc-color-text-7);
}
.lc-search-form-collapse .hasData .el-input__prefix {
  font-weight: 700 !important;
}
.lc-search-form-collapse .search-btn-container {
  display: flex;
  align-items: center;
}
.lc-search-form-collapse .search-btn-container .el-button {
  font-size: 14px;
}
.lc-search-form-collapse .search-btn-container .el-link {
  width: 64px;
}
.lc-search-form-collapse .search-btn-container .el-link.el-link--default,
.lc-search-form-collapse .search-btn-container .el-link.el-link--default:hover {
  padding: 3px 3px 3px 8px;
  box-sizing: border-box;
  height: 32px;
}
.lc-search-form-collapse .search-btn-container .el-link .collapse-btn,
.lc-search-form-collapse .search-btn-container .el-link .expand-btn {
  color: var(--lc-color-text-6);
}
.lc-search-form-collapse .search-btn-container .el-link .el-icon-arrow-up,
.lc-search-form-collapse .search-btn-container .el-link .el-icon-arrow-down {
  font-size: 14px !important;
  color: var(--lc-color-text-6);
}
.lc-search-form-collapse .search-btn-container .el-link.el-link--default:hover {
  background-color: var(--lc-color-fill-4);
  border-radius: 2px;
}
.lc-checkbox.el-checkbox.el-checkbox__label {
  display: inline-block;
}
.lc-checkbox.is-checked .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox.is-checked .el-checkbox__label {
  color: var(--lc-color-text-7);
}
.lc-checkbox.is-disabled .el-checkbox__label {
  color: var(--lc-color-text-7) !important;
}
.lc-checkbox.is-disabled .el-checkbox__input .el-checkbox__inner {
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox.is-disabled .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox.is-disabled .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 2px;
  transform: scale(0.5);
  left: 0;
  right: 0;
  top: 5px;
}
.lc-checkbox.is-disabled.is-checked .el-checkbox__input .el-checkbox__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox.is-disabled.is-checked .el-checkbox__input .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  width: 3px;
  transition: transform 0.15s ease-in 0.05s, -webkit-transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
.lc-checkbox.is-disabled.is-checked .el-checkbox__label {
  color: var(--lc-color-text-7);
}
.lc-checkbox .el-checkbox__input {
  margin-top: -1px;
}
.lc-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-border-3);
}
.lc-checkbox .el-checkbox__label {
  color: var(--lc-color-text-7);
  padding-left: 8px;
}
.lc-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox:not(.is-disabled):hover .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox :deep(.el-checkbox__label) {
  color: var(--lc-color-text-7);
}
.lc-checkbox-group {
  display: flex;
}
.lc-checkbox-group .el-checkbox.el-checkbox.el-checkbox__label {
  display: inline-block;
}
.lc-checkbox-group .el-checkbox.is-checked .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox-group .el-checkbox.is-checked .el-checkbox__label {
  color: var(--lc-color-text-7);
}
.lc-checkbox-group .el-checkbox.is-disabled .el-checkbox__label {
  color: var(--lc-color-text-7) !important;
}
.lc-checkbox-group .el-checkbox.is-disabled .el-checkbox__input .el-checkbox__inner {
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox-group .el-checkbox.is-disabled .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox-group .el-checkbox.is-disabled .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #fff;
  height: 2px;
  transform: scale(0.5);
  left: 0;
  right: 0;
  top: 5px;
}
.lc-checkbox-group .el-checkbox.is-disabled.is-checked .el-checkbox__input .el-checkbox__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-checkbox-group .el-checkbox.is-disabled.is-checked .el-checkbox__input .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 4px;
  position: absolute;
  top: 1px;
  width: 3px;
  transition: transform 0.15s ease-in 0.05s, -webkit-transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
.lc-checkbox-group .el-checkbox.is-disabled.is-checked .el-checkbox__label {
  color: var(--lc-color-text-7);
}
.lc-checkbox-group .el-checkbox .el-checkbox__input {
  margin-top: -1px;
}
.lc-checkbox-group .el-checkbox .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-border-3);
}
.lc-checkbox-group .el-checkbox .el-checkbox__label {
  color: var(--lc-color-text-7);
  padding-left: 8px;
}
.lc-checkbox-group .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox-group .el-checkbox:not(.is-disabled):hover .el-checkbox__input .el-checkbox__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-checkbox-group .el-checkbox :deep(.el-checkbox__label) {
  color: var(--lc-color-text-7);
}
.lc-radio .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-border-3);
}
.lc-radio .el-radio__label {
  color: var(--lc-color-text-7);
  padding-left: 8px;
}
.lc-radio.is-checked .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-radio.is-checked .el-radio__label {
  color: var(--lc-color-text-7);
}
.lc-radio:not(.is-disabled):hover .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-radio.is-disabled .el-radio__label {
  color: var(--lc-color-text-7) !important;
}
.lc-radio.is-disabled .el-radio__input .el-radio__inner {
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-border-5);
}
.lc-radio.is-disabled.is-checked .el-radio__input .el-radio__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-radio.is-disabled.is-checked .el-radio__input .el-radio__inner::after {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.lc-radio.is-disabled.is-checked .el-radio__label {
  color: var(--lc-color-text-7);
}
.lc-radio-group .el-radio .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-border-3);
}
.lc-radio-group .el-radio .el-radio__label {
  color: var(--lc-color-text-7);
  padding-left: 8px;
}
.lc-radio-group .el-radio.is-checked .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-radio-group .el-radio.is-checked .el-radio__label {
  color: var(--lc-color-text-7);
}
.lc-radio-group .el-radio:not(.is-disabled):hover .el-radio__input .el-radio__inner {
  border-color: var(--lc-color-brand-6);
}
.lc-radio-group .el-radio.is-disabled .el-radio__label {
  color: var(--lc-color-text-7) !important;
}
.lc-radio-group .el-radio.is-disabled .el-radio__input .el-radio__inner {
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-border-5);
}
.lc-radio-group .el-radio.is-disabled.is-checked .el-radio__input .el-radio__inner {
  background-color: var(--lc-color-fill-6);
  border-color: var(--lc-color-border-5);
}
.lc-radio-group .el-radio.is-disabled.is-checked .el-radio__input .el-radio__inner::after {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.lc-radio-group .el-radio.is-disabled.is-checked .el-radio__label {
  color: var(--lc-color-text-7);
}
.lc-input .el-input__inner {
  height: 32px;
  padding: 0 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-input .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-input .el-input__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-input .el-input__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-input .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-input .el-textarea__inner {
  min-width: 346px;
  padding: 5px 8px;
  border-radius: 0px;
}
.lc-input-unit .el-input__inner {
  height: 32px;
  padding: 0 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-input-unit .el-input-group__append,
.lc-input-unit .el-input-group__prepend {
  box-sizing: border-box;
  width: 30px;
  height: 32px;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 0;
  color: var(--lc-color-text-5);
  background-color: var(--lc-color-fill-4);
}
.lc-input-unit .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-input-unit .el-input__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-input-unit .el-input__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-input-unit .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-input-textarea .el-textarea__inner {
  width: 346px;
  padding: 5px 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-input-textarea .el-textarea__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-input-textarea .el-textarea__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-input-textarea .el-textarea__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-input-textarea .el-textarea__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-input-autocomplete {
  width: 100%;
}
.lc-input-autocomplete .el-input__inner {
  height: 32px;
  padding: 0 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-input-autocomplete .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-input-autocomplete .el-input__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-input-autocomplete .el-input__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-input-autocomplete .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-input-autocomplete .el-textarea__inner {
  min-width: 346px;
  padding: 5px 8px;
  border-radius: 0px;
}
.el-autocomplete-suggestion {
  border: 1px solid var(--lc-color-border-3);
}
.el-autocomplete-suggestion .el-autocomplete-suggestion__wrap {
  padding: 6px 0;
}
.el-autocomplete-suggestion .el-autocomplete-suggestion__list [id^="el-autocomplete"] {
  height: 32px;
  color: var(--lc-color-fill-7);
  display: flex;
  align-items: center;
}
.el-autocomplete-suggestion .el-autocomplete-suggestion__list [id^="el-autocomplete"]:hover {
  background-color: var(--lc-color-brand-1);
}
.lc-select {
  width: 100%;
}
.lc-select .el-input__inner {
  height: 32px;
  padding: 0 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-select .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-select .el-input__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-select .el-input__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-select .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-select .el-input__suffix-inner .el-input__icon {
  height: 32px;
  line-height: 32px;
}
.lc-select .el-tag--info {
  margin: 2px 0 2px 4px;
  padding: 0 6px 0 8px;
  height: 24px;
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-6);
  border-color: var(--lc-color-border-3);
}
.lc-select .el-tag .el-icon-close {
  font-size: 14px;
  right: -4px;
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-select .el-tag .el-icon-close:hover {
  right: -4px;
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-brand-6);
}
.lc-select-popper.el-select-dropdown {
  border: 1px solid var(--lc-color-border-3);
}
.lc-select-popper.el-select-dropdown .el-select-dropdown__list {
  padding: 6px 0;
}
.lc-select-popper.el-select-dropdown .el-select-dropdown__list .el-select-dropdown__item {
  height: 32px;
  margin-bottom: 0px;
  color: var(--lc-color-fill-7);
  display: flex;
  align-items: center;
}
.lc-select-popper.el-select-dropdown .el-select-dropdown__list .el-select-dropdown__item.hover {
  background-color: var(--lc-color-brand-1);
}
.lc-select-popper.el-select-dropdown .el-select-dropdown__list .el-select-dropdown__item.selected {
  color: var(--lc-color-brand-6);
}
.listPageInfo {
  --lc-font-size-base: 14px;
}
.listPageInfo .el-card__body {
  padding: 16px 20px 4px 2px;
}
.listPageInfo .tableBlock {
  border: 8px solid #e6eaf5;
  border-bottom: 0px;
  padding: 20px 20px 0 20px;
  background: #fff;
}
.listPageInfo .paginationBlock {
  display: flex;
  justify-content: flex-end;
  border: 8px solid #e6eaf5;
  background: #fff;
  border-top: 0px;
}
.listPageInfo .paginationBlock .el-pagination {
  padding: 20px 20px 30px 0px;
}
.lc-form-custom .el-form-item__label {
  line-height: 32px;
  text-align: left;
  color: var(--lc-color-text-5);
}
.lc-form-custom .el-form-item__label::before {
  position: absolute;
  margin-left: -8px;
}
.lc-form-custom .el-form-item__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  line-height: 32px;
  position: relative;
  font-size: 14px;
  min-width: 0;
}
.lc-form-custom .el-form-item__content .el-input-group {
  vertical-align: top;
}
.lc-form-custom .el-form-item__content .el-row {
  flex: 1;
}
.lc-form-custom .el-form-item__content .el-radio {
  line-height: 32px;
}
.lc-form-custom .lc-item-container .el-form-item__content {
  overflow: hidden;
}
.lc-form-custom .el-select {
  width: 100%;
}
.lc-form-custom .el-input .el-input__inner {
  width: inherit;
}
.lc-form-custom .el-form-item {
  margin-bottom: 16px;
}
.lc-cascader.el-cascader {
  line-height: inherit;
  width: 100%;
}
.lc-cascader .el-input__inner {
  height: 32px;
  padding: 0 8px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-cascader .el-input__suffix-inner .el-input__icon {
  height: 32px;
  line-height: 32px;
}
.lc-cascader .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-cascader .el-input__inner:focus {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-cascader .el-input__inner:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-cascader .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-cascader .el-tag--info {
  margin: 2px 0 2px 4px;
  padding: 0 8px;
  height: 24px;
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-6);
}
.lc-cascader .el-tag__close.el-icon-close {
  background-color: var(--lc-color-fill-6);
  color: var(--lc-color-fill-1);
}
.lc-cascader.el-cascader__dropdown {
  border: 1px solid var(--lc-color-border-3);
}
.lc-cascader.el-cascader__dropdown .el-icon-arrow-right {
  color: var(--lc-color-text-3);
}
.lc-cascader.el-cascader__dropdown .el-icon-check {
  display: none;
}
.lc-cascader.el-cascader__dropdown .el-cascader-node {
  height: 32px;
  padding: 0 30px 0 10px;
  color: var(--lc-color-fill-7);
}
.lc-cascader.el-cascader__dropdown .el-cascader-node.is-active {
  color: var(--lc-color-brand-6);
}
.lc-cascader.el-cascader__dropdown .el-cascader-node.in-active-path {
  color: var(--lc-color-brand-6);
}
.lc-cascader.el-cascader__dropdown .el-cascader-node:not(.is-disabled):focus,
.lc-cascader.el-cascader__dropdown .el-cascader-node:not(.is-disabled):hover {
  background: var(--lc-color-brand-1);
}
.lc-date-picker.el-date-editor {
  width: 100%;
  height: 32px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 0px solid var(--lc-color-border-3);
}
.lc-date-picker.el-date-editor:hover {
  border: 0px solid var(--lc-color-border-5);
}
.lc-date-picker.el-date-editor .el-input__suffix .el-input__icon.el-icon-circle-close {
  font-size: 14px;
  color: var(--lc-color-icon-1);
}
.lc-date-picker.el-date-editor .el-input__inner {
  height: 32px;
  line-height: 32px;
  padding-left: 32px;
  outline: none;
  border-radius: 0;
  color: var(--lc-color-fill-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-date-picker.el-date-editor .el-input__inner:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-date-picker.el-date-editor .el-input__inner:focus {
  outline: none;
  border: var(--lc-color-brand-6) solid 1px;
  border-radius: 0;
}
.lc-date-picker.el-date-editor .el-input__inner::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-date-picker.el-date-editor .el-input__prefix {
  left: 4px;
  color: var(--lc-color-text-3);
}
.lc-date-picker.el-date-editor .el-input__prefix .el-input__icon {
  width: 24px;
}
.lc-date-picker.el-date-editor .el-input__prefix .el-input__icon:before {
  position: relative;
  top: -4px;
}
.lc-date-picker.el-date-editor .el-input__suffix .el-input__icon {
  line-height: 32px;
  color: var(--lc-color-border-3);
}
.lc-date-picker.el-range-editor {
  width: 100%;
  height: 32px;
  border-radius: 0px;
  color: var(--lc-color-text-7);
  border: 1px solid var(--lc-color-border-3);
}
.lc-date-picker.el-range-editor:hover {
  border: 1px solid var(--lc-color-border-5);
}
.lc-date-picker.el-range-editor.is-active {
  border: 1px solid var(--lc-color-brand-6);
}
.lc-date-picker.el-range-editor:disabled {
  border: 1px solid var(--lc-color-border-3);
  background-color: var(--lc-color-fill-4);
  color: var(--lc-color-text-3);
}
.lc-date-picker.el-range-editor.el-range-editor {
  text-align: center;
  padding: 3px 8px;
}
.lc-date-picker.el-range-editor .el-input__icon,
.lc-date-picker.el-range-editor .el-input__prefix,
.lc-date-picker.el-range-editor .el-range-separator {
  color: var(--lc-color-text-3);
  height: 32px;
  font-size: 16px;
}
.lc-date-picker.el-range-editor .el-range__close-icon {
  color: var(--lc-color-icon-1);
  font-size: 14px;
}
.lc-date-picker.el-range-editor .el-range-separator {
  font-size: 14px;
  color: var(--lc-color-text-6);
}
.lc-date-picker.el-range-editor .el-range-input {
  color: var(--lc-color-fill-7);
}
.lc-date-picker.el-range-editor .el-range-input::placeholder {
  color: var(--lc-color-text-3) !important;
}
.lc-date-picker.el-picker-panel {
  border: 0px solid var(--lc-color-border-3);
  height: auto;
}
.lc-date-picker.el-picker-panel .el-picker-panel__body {
  background: #ffffff;
  border: 1px solid var(--lc-color-border-3);
}
.lc-date-picker.el-picker-panel .el-picker-panel__body .el-picker-panel__icon-btn {
  color: var(--lc-color-text-3);
  font-size: 14px;
}
.lc-date-picker.el-picker-panel .el-picker-panel__body .el-date-picker__header-label {
  color: var(--lc-color-text-6);
}
.lc-date-picker.el-picker-panel .el-picker-panel__body .el-date-picker__header-label:hover {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-picker-panel__body .el-date-range-picker__header div {
  color: var(--lc-color-text-6);
}
.lc-date-picker.el-picker-panel .el-picker-panel__body .el-date-range-picker__header div:hover {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-date-table {
  box-sizing: border-box;
}
.lc-date-picker.el-picker-panel .el-date-table th {
  color: var(--lc-color-border-6);
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row:hover div {
  background-color: transparent;
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row td.in-range div,
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row td.in-range div:hover {
  background-color: var(--lc-color-brand-1);
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row .prev-month {
  color: var(--lc-color-border-5);
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row .available {
  color: var(--lc-color-fill-7);
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row .available:hover {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-date-table .el-date-table__row .available.today {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row:hover .available div {
  background-color: transparent;
}
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row:hover .in-range div {
  background-color: var(--lc-color-brand-1);
}
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row.end-date span,
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row.start-date span,
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row.current:not(.disabled) span {
  background-color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-date-range-picker__content .el-date-table .el-date-table__row.in-range div {
  background-color: var(--lc-color-brand-1);
}
.lc-date-picker.el-picker-panel .is-week-mode .el-date-table__row:hover div,
.lc-date-picker.el-picker-panel .is-week-mode .el-date-table__row.current div {
  background-color: var(--lc-color-brand-1);
}
.lc-date-picker.el-picker-panel .el-month-table td.current:not(.disabled) div .cell {
  width: 60px;
  height: 36px;
  display: block;
  line-height: 36px;
  margin: 0 auto;
  border-radius: 18px;
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-month-table td.in-range div,
.lc-date-picker.el-picker-panel .el-month-table td.in-range div:hover {
  background-color: var(--lc-color-brand-1);
}
.lc-date-picker.el-picker-panel .el-year-table .available {
  font-size: 14px;
}
.lc-date-picker.el-picker-panel .el-year-table .available .cell {
  color: var(--lc-color-text-3);
}
.lc-date-picker.el-picker-panel .el-year-table .available .cell:hover {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-year-table .available.today > .cell {
  color: var(--lc-color-brand-6);
}
.lc-date-picker.el-picker-panel .el-year-table .available.current:not(.disabled) > .cell {
  border-radius: 18px;
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-6);
}
.lc-switch .el-switch__core {
  background-color: var(--lc-color-border-5);
  border-color: var(--lc-color-border-5);
}
.lc-switch.is-checked .el-switch__core {
  background-color: var(--lc-color-success-4);
  border-color: var(--lc-color-success-4);
}
.el-tooltip__popper.is-light.lcNewTooltipClass {
  font-size: 12px;
  color: var(--lc-color-fill-7);
  line-height: 20px;
  font-weight: 400;
  background: #fff;
  padding: 8px 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: left;
  max-width: 750px;
  border: 0;
}
.el-tooltip__popper.is-light.lcNewTooltipClass .popper__arrow {
  border-style: none;
}
.el-tooltip.default {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: var(--lc-color-text-7);
}
.el-tooltip.default i.a-rezenonepc-Help,
.el-tooltip.default i.a-rezenonepc-Information {
  margin-right: 4px;
}
.el-tooltip.noun {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: var(--lc-color-text-3);
}
.el-tooltip.noun i.a-rezenonepc-Help,
.el-tooltip.noun i.a-rezenonepc-Information {
  margin-right: 4px;
}
.el-tooltip.ability {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: var(--lc-color-text-3);
}
.el-tooltip.ability i.a-rezenonepc-Help,
.el-tooltip.ability i.a-rezenonepc-Information {
  margin-right: 4px;
}
.lc-dlg.el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
.lc-dlg .el-dialog__header {
  padding: 10px 20px;
  border-bottom: 1px solid #E3E7ED;
  color: var(--lc-color-text-7);
}
.lc-dlg .el-dialog__header .el-dialog__title {
  font-size: 16px;
  line-height: 22px;
}
.lc-dlg .el-dialog__header .el-dialog__headerbtn {
  top: 12px;
  right: 14px;
}
.lc-dlg .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: var(--lc-color-text-3);
}
.lc-dlg .el-dialog__header .el-dialog__headerbtn .el-dialog__close:hover {
  color: var(--lc-color-brand-6);
}
.lc-dlg .el-dialog__body {
  padding: 0px;
  color: var(--lc-color-text-7);
}
.lc-dlg .el-dialog__footer {
  text-align: right;
  padding: 12px 20px;
  border-top: 1px solid #EEEEEE;
}
.lc-dlg .el-button.dialog-btn {
  display: inline-flex;
  align-items: center;
  padding: 8px 30px;
  border-radius: 2px;
  font-weight: 400;
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-6);
  border-color: var(--lc-color-brand-6);
}
.lc-dlg .el-button.dialog-btn:hover {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-5);
  border-color: var(--lc-color-brand-5);
}
.lc-dlg .el-button.dialog-btn:active {
  color: var(--lc-color-text-1);
  background-color: var(--lc-color-brand-7);
  border-color: var(--lc-color-brand-7);
}
.lc-dlg .el-button.dialog-btn.cancel {
  margin-left: 4px;
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-text-1);
  border-color: var(--lc-color-fill-6);
}
.lc-dlg .el-button.dialog-btn.cancel:hover {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-4);
  border-color: var(--lc-color-fill-6);
}
.lc-dlg .el-button.dialog-btn.cancel:active {
  color: var(--lc-color-text-7);
  background-color: var(--lc-color-fill-5);
  border-color: var(--lc-color-fill-6);
}
.lc-dlg.small .el-dialog__body {
  min-height: 160px;
  overflow: auto;
}
.lc-dlg.middle .el-dialog__body {
  min-height: 300px;
  overflow: auto;
}
.lc-dlg.large .el-dialog__body {
  min-height: 660px;
  overflow: auto;
}
.lc-views-table {
  border-left: 1px solid var(--lc-color-border-2);
  border-right: 1px solid var(--lc-color-border-2);
  border-top: 1px solid var(--lc-color-border-2);
  display: inline-block;
}
.lc-views-table .list {
  display: flex;
  align-items: center;
  line-height: 16px;
  font-size: 14px;
  color: var(--lc-color-text-7);
  border-bottom: 1px solid var(--lc-color-border-2);
}
.lc-views-table .list .label {
  width: 112px;
  padding: 11px 12px;
  background: var(--lc-color-fill-4);
  color: var(--lc-color-text-5);
}
.lc-views-table .list .info {
  box-sizing: border-box;
  padding: 11px 12px;
}
.lc-views-table.views-vertical .list .info {
  width: 560px;
}
.lc-views-table.views-horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.lc-views-table.views-horizontal .list {
  min-width: 25%;
}
.lc-views-table.views-horizontal .list.fill-up {
  flex-grow: 1;
}
.el-message.lc-message {
  min-width: 160px;
  max-width: 280px;
  line-height: 22px;
  padding: 9px 12px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
}
.el-message.lc-message .el-message__icon {
  margin-right: 8px;
}
.el-message.lc-message .el-message__icon.el-icon-info {
  color: #5E8AF2;
}
.el-message.lc-message .el-message__icon.el-icon-success {
  color: #00BF66;
}
.el-message.lc-message .el-message__icon.el-icon-warning {
  color: #FFAD33;
}
.el-message.lc-message.el-message--success {
  border-color: #8DE0B8;
  background: #E8FFF5;
}
.el-message.lc-message.el-message--success .el-message__content {
  color: #0F2347;
}
.el-message.lc-message.el-message--warning {
  border-color: #FFC369;
  background: #FFF3E2;
}
.el-message.lc-message.el-message--warning .el-message__content {
  color: #0F2347;
}
.el-message.lc-message.el-message--error {
  border-color: #FE8780;
  background: #FFF6F6;
}
.el-message.lc-message.el-message--error .el-message__content {
  color: #0F2347;
}
.el-message.lc-message.el-message--info {
  border-color: #B1C4F2;
  background: #F2F4FA;
}
.el-message.lc-message.el-message--info .el-message__content {
  color: #0F2347;
}
.about {
  padding: 30px 0;
}
div.el-popper:not([role^='tooltip']) div.popper__arrow {
  display: none;
}
div.el-popper:not([role^='tooltip'])[x-placement^=top] {
  margin-bottom: 4px;
}
div.el-popper:not([role^='tooltip'])[x-placement^=bottom] {
  margin-top: 4px;
}
div.el-popper:not([role^='tooltip'])[x-placement^=right] {
  margin-left: 4px;
}
div.el-popper:not([role^='tooltip'])[x-placement^=left] {
  margin-right: 4px;
}
